import { z } from 'zod';
import {
  zCalendarDate,
  zDecimalNullable,
  zDecimalRequired,
  zIsoDate,
} from '@harvestiq/zod';
import {
  CampaignCoverageTier,
  InsureIQPolicyTypes,
  RmaLrpCommodityTypeCodes,
  RmaLgmCommodityTypeCodes,
} from '@harvestiq/constants';
import { orgAppSettingsSchema } from '../org-app-settings/org-app-settings';

// Quote download
export const insureIQQuoteDownloadRequestSchema = z.object({
  policyType: z.nativeEnum(InsureIQPolicyTypes),
  commodityTypeCode: z.string(),
  numberOfHead: z.string(),
  marketingMonths: z.array(z.coerce.number()),
  targetSalesEffectiveDate: zCalendarDate().nullable().optional(),
  deductible: z.string(),
  endorsementLength: z.coerce.number().nullish(),
  endDate: zIsoDate().nullable().optional(),
  targetWeight: zDecimalNullable().optional(),
  liveCattleTargetCwtPerHead: zDecimalNullable().optional(),
  feederCattleTargetCwtPerHead: zDecimalNullable().optional(),
  cornTargetBuPerHead: zDecimalNullable().optional(),
  coverageTier: z.nativeEnum(CampaignCoverageTier).nullable().optional(),
});
export type InsureIQQuoteDownloadRequest = z.input<
  typeof insureIQQuoteDownloadRequestSchema
>;

export const insureIQQuoteDownloadResponseSchema = z.object({
  filename: z.string(),
  salesEffectiveDate: zIsoDate(),
});

export type InsureIQQuoteDownloadResponse = z.infer<
  typeof insureIQQuoteDownloadResponseSchema
>;

export type InsureIQQuoteDownloadApiResponse = z.infer<
  typeof insureIQSendQuoteEmailResponseSchema
>;

// On-demand quote share
export const insureIQQuoteEmailRequestSchema =
  insureIQQuoteDownloadRequestSchema.extend({
    recipients: z.array(z.string().email()),
    message: z.string().optional(),
    sendPreview: z.boolean().optional(),
    allowExternalRecipients: z.boolean().optional(),
  });

export type InsureIQQuoteEmailRequest = z.infer<
  typeof insureIQQuoteEmailRequestSchema
>;

// Quote campaign
export const insureIQCommodityTypeCodesSchema = z.union([
  z.nativeEnum(RmaLrpCommodityTypeCodes),
  z.nativeEnum(RmaLgmCommodityTypeCodes),
]);
export type InsureIQCommodityTypeCodes = z.infer<
  typeof insureIQCommodityTypeCodesSchema
>;
export const insureIQQuoteCampaignRequestSchema = z.object({
  // in situations where the caller explicitly wants a historical quote
  targetSalesEffectiveDate: zCalendarDate().nullish(),
  policyType: z.nativeEnum(InsureIQPolicyTypes),
  message: z.string().optional(),
  targetWeight: zDecimalNullable(),
  numberOfHead: z.number().int().min(1).nullable(),
  coverageTier: z.nativeEnum(CampaignCoverageTier).nullable().optional(),
  liveCattleTargetCwtPerHead: zDecimalRequired().nullable().optional(),
  feederCattleTargetCwtPerHead: zDecimalRequired().nullable().optional(),
  cornTargetBuPerHead: zDecimalRequired().nullable().optional(),
  recipientUserIds: z.array(z.string().uuid()), // User IDs
  commodityTypeCodes: insureIQCommodityTypeCodesSchema.array(),
  marketingMonths: z.array(z.number().int().min(0).max(11)),
  endorsementLength: z.coerce.number().nullable().optional(),
  endDate: zIsoDate().nullable().optional(),
  deductibles: z.array(z.number().int().min(0).max(150)),
});
export type InsureIQQuoteCampaignRequest = z.input<
  typeof insureIQQuoteCampaignRequestSchema
>;
export type InsureIQQuoteCampaignRequestOutput = z.output<
  typeof insureIQQuoteCampaignRequestSchema
>;

export const insureIQQuoteSchema = insureIQQuoteCampaignRequestSchema.omit({
  recipientUserIds: true,
  message: true,
});
export type InsureIQQuote = z.infer<typeof insureIQQuoteSchema>;

// RMA quote schema
export const rmaQuoteRequestSchema = insureIQQuoteSchema.extend({
  salesEffectiveDate: zIsoDate(),
});
export type RmaQuoteRequest = z.infer<typeof rmaQuoteRequestSchema>;

export const rmaQuoteSchema = z.object({
  commodityTypeCode: z.number(),
  commodity: z.string().optional(),
  commodityType: z.string().optional(),
  marketingDate: zIsoDate(),
  endorsementLength: z.coerce.number().optional().nullable(),
  coveragePrice: z.number(),
  coverageLevel: z.number().optional(),
  producerPremium: z.number(),
  salesEffectiveDate: zIsoDate(),
  deductible: z.number().optional(),
  expectedEndingValue: z.number().optional(), // undefined for LGM ?
});
export type RmaQuote = z.infer<typeof rmaQuoteSchema>;

export const RmaQuoteResponseSchema = z.record(
  z.string(),
  rmaQuoteSchema.array()
);
export type RmaQuoteResponse = z.infer<typeof RmaQuoteResponseSchema>;

export const insureIQCampaignRecipientSchema = z.object({
  userId: z.string().uuid(),
  email: z.string().email(),
  name: z.string(),
  agentName: z.string().optional(),
  agentEmail: z.string().email().optional(),
});
export type InsureIQCampaignRecipient = z.infer<
  typeof insureIQCampaignRecipientSchema
>;

// TODO: split between LRP and LGM
export const insureIQQuoteResponseSchema = z.object({
  policyType: z.nativeEnum(InsureIQPolicyTypes),
  message: z.string().optional(),
  targetWeight: zDecimalNullable(),
  numberOfHead: z.number().int().min(1).nullable(),
  endorsementLength: z.coerce.number().nullable().optional(),
  coverageTier: z.nativeEnum(CampaignCoverageTier).nullable(),
  liveCattleTargetCwtPerHead: zDecimalRequired().nullable().optional(),
  feederCattleTargetCwtPerHead: zDecimalRequired().nullable().optional(),
  cornTargetBuPerHead: zDecimalRequired().nullable().optional(),
  filename: z.string(),
  fileUrl: z.string(),
  salesEffectiveDate: zIsoDate(),
  quotes: RmaQuoteResponseSchema,
  recipientUsers: z.array(insureIQCampaignRecipientSchema).optional(),
});
export type InsureIQQuoteResponse = z.infer<typeof insureIQQuoteResponseSchema>;

export const insureIQQuotePDFSchema = insureIQQuoteResponseSchema
  .omit({
    filename: true,
    fileUrl: true,
    message: true,
    recipientUsers: true,
  })
  .extend({
    commodityTypeCodes: insureIQCommodityTypeCodesSchema.array(),
    marketingMonths: z.array(z.coerce.number()),
  });
export type InsureIQQuotePDF = z.infer<typeof insureIQQuotePDFSchema>;

export const insureIQInsuranceQuoteResponseSchema = z.object({
  data: insureIQQuoteResponseSchema.nullable(),
  orgAppSettings: orgAppSettingsSchema,
});
export type InsureIQInsuranceQuoteResponse = z.infer<
  typeof insureIQInsuranceQuoteResponseSchema
>;

export const insuranceQuoteTemplateDataSchema =
  insureIQQuoteResponseSchema.extend({
    unsubscribeURL: z.string().url().optional(),
  });

export type InsuranceQuoteTemplateData = z.infer<
  typeof insuranceQuoteTemplateDataSchema
>;

export const insureIQSendQuoteEmailRequestSchema = z.object({
  message: z.string().optional(),
  policyType: z.nativeEnum(InsureIQPolicyTypes),
  commodityTypeCode: z.string(),
  marketingMonths: z.array(z.coerce.number()),
  deductible: zDecimalRequired(),
  targetWeight: zDecimalRequired(),
  coverageTier: z.nativeEnum(CampaignCoverageTier).nullable().optional(),
  liveCattleTargetCwtPerHead: zDecimalNullable().optional(),
  feederCattleTargetCwtPerHead: zDecimalNullable().optional(),
  cornTargetBuPerHead: zDecimalNullable().optional(),
  numberOfHead: z.number().int().min(1),
  recipients: z.array(z.string().email()),
});

export type InsureIQSendQuoteEmailRequest = z.infer<
  typeof insureIQSendQuoteEmailRequestSchema
>;

export const insureIQSendQuoteEmailResponseSchema = z.object({
  data: z
    .union([
      z.object({
        data: z.array(z.any()).optional(),
      }),
      z.array(z.any()),
    ])
    .optional(),
  errors: z.array(z.any()).optional(),
});

export type InsureIQSendQuoteEmailResponse = z.infer<
  typeof insureIQSendQuoteEmailResponseSchema
>;
