import { ProgramIndicatorDescriptions } from '../InsuranceTypes';

// See: RMA https://pubfs-rma.fpac.usda.gov/pub/References/insurance_control_elements/PASS/2024/2024_D00154_IceProgramIndicator_YTD.txt
export enum RmaProgramIndicatorCodes {
  // NOTE: RMA contains additional program indicator codes that are not included here as they are not used
  P10BeginningFarmerRancher = 'BF',
  P10VeteranFarmerRancher = 'VF',
}

export const RmaProgramIndicatorDescriptions = {
  [RmaProgramIndicatorCodes.P10BeginningFarmerRancher]:
    ProgramIndicatorDescriptions.BeginningFarmerRancher,
  [RmaProgramIndicatorCodes.P10VeteranFarmerRancher]:
    ProgramIndicatorDescriptions.VeteranFarmerRancher,
};

export const rmaProgramIndicatorOptions: { key: string; value: string }[] =
  Object.entries(RmaProgramIndicatorDescriptions).map(([key, value]) => ({
    key: key,
    value: value,
  }));

export const insureIQProgramIndicatorOptions: { key: string; value: string }[] =
  rmaProgramIndicatorOptions.concat([
    {
      key: '',
      value: 'Neither',
    },
  ]);

// Determine the program indicator based on the legacy descriptions
export const getRmaProgramIndicatorCode = (
  applicationTypeOption: ProgramIndicatorDescriptions | undefined
) => {
  let programIndicator = null;
  if (
    applicationTypeOption ===
    ProgramIndicatorDescriptions.BeginningFarmerRancher
  ) {
    programIndicator = RmaProgramIndicatorCodes.P10BeginningFarmerRancher;
  }
  if (
    applicationTypeOption === ProgramIndicatorDescriptions.VeteranFarmerRancher
  ) {
    programIndicator = RmaProgramIndicatorCodes.P10VeteranFarmerRancher;
  }
  return programIndicator;
};

export function getProgramIndicatorDescriptionsFromRmaProgramIndicatorCodes(
  programIndicator: RmaProgramIndicatorCodes | string | null
): ProgramIndicatorDescriptions {
  switch (programIndicator) {
    case RmaProgramIndicatorCodes.P10BeginningFarmerRancher:
      return ProgramIndicatorDescriptions.BeginningFarmerRancher;
    case RmaProgramIndicatorCodes.P10VeteranFarmerRancher:
      return ProgramIndicatorDescriptions.VeteranFarmerRancher;
    default:
      return ProgramIndicatorDescriptions.Neither;
  }
}
