import { RmaEntityTypeCodes } from '@harvestiq/constants';
import { FmhEntityType } from '@harvestiq/external-clients/fmh/common';

export const fmhEntityTypeToRmaEntityType: Record<
  FmhEntityType,
  RmaEntityTypeCodes
> = {
  [FmhEntityType.PublicSchools]: RmaEntityTypeCodes.PublicSchools,
  [FmhEntityType.BureauOfIndianAffairsAndIndianTribeTreaties]:
    RmaEntityTypeCodes.BureauOfIndianAffairsAndIndianTribeTreaties,
  [FmhEntityType.Corporation]: RmaEntityTypeCodes.Corporation,
  [FmhEntityType.Estates]: RmaEntityTypeCodes.Estates,
  [FmhEntityType.NonProfitOrTaxExemptOrganizations]:
    RmaEntityTypeCodes.NonProfitOrTaxExemptOrganizations,
  [FmhEntityType.TransferOfRightToIndemnity]:
    RmaEntityTypeCodes.TransferOfRightToIndemnity,
  [FmhEntityType.ReceiverOrLiquidator]: RmaEntityTypeCodes.ReceiverOrLiquidator,
  [FmhEntityType.StateLocalGovernment]: RmaEntityTypeCodes.StateLocalGovernment,
  [FmhEntityType.Individuals]: RmaEntityTypeCodes.Individuals,
  [FmhEntityType.JointOperationsVenturesAndCoOwnerships]:
    RmaEntityTypeCodes.JointOperationsVenturesAndCoOwnerships,
  [FmhEntityType.Partnership]: RmaEntityTypeCodes.Partnership,
  [FmhEntityType.TrustRevocable]: RmaEntityTypeCodes.TrustRevocable,
  [FmhEntityType.SpousalMarried]: RmaEntityTypeCodes.SpousalMarried,
  [FmhEntityType.TrustIrrevocable]: RmaEntityTypeCodes.TrustIrrevocable,
  [FmhEntityType.IndividualOperatingAsABusiness]:
    RmaEntityTypeCodes.IndividualOperatingAsABusiness,
  [FmhEntityType.LimitedLiabilityCompany]:
    RmaEntityTypeCodes.LimitedLiabilityCompany,
};
