import { z } from 'zod';
import { entitySchema } from './entity';
import { entityHistorySchema } from '../entities-history/entity-history';

// request types

export const searchEntitiesRequestSchema = z.object({
  ids: z.array(z.string()).optional(),
  searchText: z.string().optional(),
  searchFields: z
    .array(z.enum(['firstName', 'lastName', 'businessName', 'email']))
    .optional(),
  ownerIds: z.array(z.string()).optional(),
  skip: z.coerce.number().int().nonnegative().optional(),
  limit: z.coerce.number().int().positive().optional(),
});

export type SearchEntitiesRequest = z.infer<typeof searchEntitiesRequestSchema>;

// response types

export const entityWithLatestHistorySchema = entitySchema.extend({
  latestEntityHistory: entityHistorySchema,
});

export type EntityWithLatestHistory = z.infer<
  typeof entityWithLatestHistorySchema
>;

export const searchEntitiesResponseSchema = z.object({
  entities: z.array(entityWithLatestHistorySchema),
  totalCount: z.number(),
});

export type SearchEntitiesResponse = z.infer<
  typeof searchEntitiesResponseSchema
>;
