import {
  InsureIQLgmCommodityTypeNames,
  RmaLgmCommodityTypeCodes,
  InsureIQCoverageStatus,
  InsureIQPolicyTypes,
  InsureIQLgmCommodityNames,
} from '@harvestiq/constants';
import Decimal from 'decimal.js';
import {
  GeneratedAlways,
  ColumnType,
  Selectable,
  Insertable,
  Updateable,
} from 'kysely';
import {
  LgmCoverageDetails,
  lgmCoverageDetailsSchema,
} from './coverage-details';
import { dbSharedEndorsementSchema } from './shared';
import { z } from 'zod';
import {
  zDate,
  zDecimal,
  zDecimalNullable,
  zDecimalRequired,
} from '@harvestiq/zod';
import {
  LgmIndemnityDetails,
  lgmIndemnityDetailsSchema,
} from './lgm-indemnity-details';
import { DbColumnDecimalNullable } from '@harvestiq/utils';
import { expectTypeOf } from 'expect-type';

/**
 * Represents an eventual LGM Endorsement table in the database.
 */
export type LgmEndorsementsTable = {
  id: GeneratedAlways<string>;
  agentSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  commodity: InsureIQLgmCommodityNames;
  commodityType: InsureIQLgmCommodityTypeNames;
  commodityTypeCode: RmaLgmCommodityTypeCodes | null;
  createdBy: string | null;
  customerSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  details: LgmCoverageDetails;
  lgmIndemnityDetails: LgmIndemnityDetails | null;
  externalStatus: string | null;
  isExternallyManaged: boolean;
  reinsuranceYear: number;
  policyId: string | null;
  entityHistoryId: string | null;
  salesEffectiveDate: string;
  status: InsureIQCoverageStatus;
  submittedToAipAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  type: InsureIQPolicyTypes;
  watchedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  liveCattleTargetCwtPerHead: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  feederCattleTargetCwtPerHead: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  cornTargetBuPerHead: ColumnType<Decimal | null, number | null, number | null>;
  importSourceId: string | null;
  deductible: ColumnType<number | null, number | null, number | null>;
  totalPremium: ColumnType<Decimal | null, number | null, number | null>;
  subsidyTotal: ColumnType<Decimal | null, number | null, number | null>;
  producerPremiumTotal: DbColumnDecimalNullable;
  grossMarginGuaranteeTotal: DbColumnDecimalNullable;
  lgmActualGrossMarginTotal: DbColumnDecimalNullable;
  lgmActualIndemnityTotal: DbColumnDecimalNullable;
  lgmNetActualIndemnityTotal: DbColumnDecimalNullable;
  lgmProjectedGrossMarginTotal: DbColumnDecimalNullable;
  lgmProjectedIndemnityTotal: DbColumnDecimalNullable;
  lgmNetProjectedIndemnityTotal: DbColumnDecimalNullable;

  createdAt: ColumnType<Date, Date | string | undefined, Date | string>;
  updatedAt: ColumnType<Date, Date | string | undefined, Date | string>;
  deletedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
};

export type DbLgmEndorsement = Selectable<LgmEndorsementsTable>;
export type DbLgmEndorsementInsert = Insertable<LgmEndorsementsTable>;
export type DbLgmEndorsementUpdate = Updateable<LgmEndorsementsTable>;

export const dbLgmEndorsementSchema = dbSharedEndorsementSchema.extend({
  commodity: z.nativeEnum(InsureIQLgmCommodityNames),
  type: z.literal(InsureIQPolicyTypes.LGM),
  commodityType: z.nativeEnum(InsureIQLgmCommodityTypeNames),
  commodityTypeCode: z.nativeEnum(RmaLgmCommodityTypeCodes).nullable(),
  details: lgmCoverageDetailsSchema,
  liveCattleTargetCwtPerHead: zDecimalRequired().nullable(),
  feederCattleTargetCwtPerHead: zDecimalRequired().nullable(),
  cornTargetBuPerHead: zDecimalRequired().nullable(),
  importSourceId: z.string().nullable(),
  lgmIndemnityDetails: lgmIndemnityDetailsSchema.nullable(),
  deductible: z.number().nullable(),
  totalPremium: zDecimalNullable(),
  subsidyTotal: zDecimalNullable(),
  producerPremiumTotal: zDecimalNullable(),
  grossMarginGuaranteeTotal: zDecimalNullable(),
  lgmActualGrossMarginTotal: zDecimalNullable(),
  lgmActualIndemnityTotal: zDecimalNullable(),
  lgmNetActualIndemnityTotal: zDecimalNullable(),
  lgmProjectedGrossMarginTotal: zDecimalNullable(),
  lgmProjectedIndemnityTotal: zDecimalNullable(),
  lgmNetProjectedIndemnityTotal: zDecimalNullable(),
});

expectTypeOf<keyof DbLgmEndorsement>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementSchema>
>();

export const dbLgmEndorsementInsertSchema = dbLgmEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    liveCattleTargetCwtPerHead: z.number().nullable(),
    feederCattleTargetCwtPerHead: z.number().nullable(),
    cornTargetBuPerHead: z.number().nullable(),
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
    // new fields
    lgmIndemnityDetails: lgmIndemnityDetailsSchema.nullable(),
    deductible: z.number().nullable(),
    totalPremium: z.number().nullable(),
    subsidyTotal: z.number().nullable(),
    producerPremiumTotal: zDecimalNullable(),
    grossMarginGuaranteeTotal: zDecimalNullable(),
    lgmActualGrossMarginTotal: zDecimalNullable(),
    lgmActualIndemnityTotal: zDecimalNullable(),
    lgmNetActualIndemnityTotal: zDecimalNullable(),
    lgmProjectedGrossMarginTotal: zDecimalNullable(),
    lgmProjectedIndemnityTotal: zDecimalNullable(),
    lgmNetProjectedIndemnityTotal: zDecimalNullable(),
  });

expectTypeOf<keyof DbLgmEndorsementInsert>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementInsertSchema>
>();

export const dbLgmEndorsementUpdateSchema =
  dbLgmEndorsementInsertSchema.partial();

expectTypeOf<keyof DbLgmEndorsementUpdate>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementUpdateSchema>
>();
