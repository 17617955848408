import { PHONE_NUMBER_REGEX } from '@harvestiq/zod';
import { DateFormatShort, InsureIQPolicyTypes } from '@harvestiq/constants';
import { dayjs, diffInWeeks } from './dayjs';
import Decimal from 'decimal.js';

export const formatCurrency = (
  v: number | Decimal | null,
  options: Intl.NumberFormatOptions = {}
) => {
  if (v === null) {
    return '--';
  }

  if (Decimal.isDecimal(v)) {
    return v.toNumber().toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      ...options,
    });
  }
  return v.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  });
};

export const formatPercent = (v: number | Decimal, digits = 2) =>
  `${new Decimal(v).times(100).toFixed(digits)}%`;

export function formatNumber(
  value: number | Decimal,
  decimalPlaces = 2
): string {
  let val: number;
  if (Decimal.isDecimal(value)) {
    val = value.toNumber();
  } else {
    val = value;
  }
  return val.toLocaleString('en-US', { maximumFractionDigits: decimalPlaces });
}

export const removePhoneMask = (phone: string) =>
  // First capture group is the country code
  phone.replace(PHONE_NUMBER_REGEX, '$2$3$4');

export const formatMarketingDate = (
  policyType: string,
  marketingDate: string,
  salesEffectiveDate: string
) => {
  if (policyType === InsureIQPolicyTypes.LRP) {
    const endorsementLength = diffInWeeks(
      dayjs(salesEffectiveDate),
      dayjs(marketingDate)
    );
    return `${endorsementLength} weeks - ${marketingDate}`;
  }
  return dayjs(marketingDate).format(DateFormatShort);
};

export const slugify = (str: string, separator = '-') =>
  str
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 -]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);

export const formatPhone = (phone: string | undefined) =>
  phone?.replace(/^(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
export const formatSSN = (ssn: string) =>
  ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
export const formatEIN = (ein: string) =>
  ein.replace(/(\d{2})(\d{7})/, '$1-$2');
