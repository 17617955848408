import {
  DbOrderDirection,
  InsureIQCoverageStatus,
  InsureIQPolicyTypes,
  InsureIQImportSource,
  RmaTypeCodes,
} from '@harvestiq/constants';
import { z } from 'zod';
import { dbLrpEndorsementSchema } from './db-lrp-endorsement';
import { dbLgmEndorsementSchema } from './db-lgm-endorsement';
import { zCoercedBoolean, zIsoDate } from '@harvestiq/zod';
import { coverageSchema } from './coverage';

export const getCoveragesRequestSchema = z.object({
  type: z.nativeEnum(InsureIQPolicyTypes).optional(),
  typeCodes: z.array(z.nativeEnum(RmaTypeCodes)).optional(),
  statuses: z.array(z.nativeEnum(InsureIQCoverageStatus)).optional(),
  policyIds: z.array(z.string().uuid()).optional(),
  userOwnerIds: z.array(z.string().uuid()).optional(),
  entityIds: z.array(z.string().uuid()).optional(),
  salesEffectiveDates: z.array(z.string()).optional(), // a list of specific sales effective dates
  salesEffectiveDateStart: zIsoDate().optional(), // a range of sales effective dates - start date
  salesEffectiveDateEnd: zIsoDate().optional(), // a range of sales effective dates - end date
  reinsuranceYears: z.array(z.coerce.number()).optional(),
  importSource: z.nativeEnum(InsureIQImportSource).optional(),
  skip: z.coerce.number().int().nonnegative().optional(),
  limit: z.coerce.number().int().positive().optional(),
  orderBy: z
    .string()
    // Only allow known coverage fields
    .refine(
      (value: string) =>
        Object.keys(dbLrpEndorsementSchema.shape).includes(value) ||
        Object.keys(dbLgmEndorsementSchema.shape).includes(value),
      {
        message: 'Invalid orderBy field',
      }
    )
    .default('createdAt'),
  order: z.nativeEnum(DbOrderDirection).default(DbOrderDirection.DESC),
  enableEntities: zCoercedBoolean().optional(),
  includeReport: zCoercedBoolean().optional(),
});

export type GetCoveragesRequest = z.infer<typeof getCoveragesRequestSchema>;
export type GetCoveragesRequestInput = z.input<
  typeof getCoveragesRequestSchema
>;

export const getCoveragesResponseSchema = z.object({
  coverages: z.array(coverageSchema),
  totalCount: z.number(),
});

export type GetCoveragesResponse = z.infer<typeof getCoveragesResponseSchema>;
