import { Insertable } from 'kysely';
import { z } from 'zod';

const featureFlagsSchema = z.object({
  notificationCrons: z.boolean(),
});

export type AppSettingsTable = {
  id: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  name: string;
  fullLogo: string;
  compactLogo: string;
  favicon: string;
  loadingIndicator: string;
  phone: string;
  email: string | null;
  domain: string;
  sendgridDomainId: string | null;
  sendgridDomainDns: string | null;
  colorScheme: {
    primary: string;
    secondary: string;
    success: string;
    info: string;
    warning: string;
    error: string;
  };
  isActive: boolean;
  isAdmin: boolean;
  gaTrackingId: string | null;
  hubspotAccountId: string | null;
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  state: string;
  zip: string;
  senderEmail: string | null;
  featureFlags: {
    notificationCrons: boolean;
  } | null;
  timezone: string | null;
  fmhApiKey: string | null;
  fmhSigningKey: string | null;
  organizationType: string;
  parentOrgId: string | null;
  stockguardSlackChannel: string;
};

export const appSettingsSchema = z.object({
  id: z.string(),
  createdAt: z.date().nullish(),
  updatedAt: z.date().nullish(),
  name: z.string(),
  fullLogo: z.string().url(), // url to logo
  compactLogo: z.string().url(), // url to logo
  favicon: z.string().url(), // url to favicon
  loadingIndicator: z.string(),
  phone: z.string(),
  email: z.string().email().nullish(),
  domain: z.string(),
  sendgridDomainId: z.string().nullish(),
  sendgridDomainDns: z.string().nullish(),
  colorScheme: z.object({
    primary: z.string(),
    secondary: z.string(),
    success: z.string(),
    info: z.string(),
    warning: z.string(),
    error: z.string(),
  }),
  isActive: z.boolean(),
  isAdmin: z.boolean(),
  gaTrackingId: z.string().nullish(),
  hubspotAccountId: z.string().nullish(),
  addressLine1: z.string(),
  addressLine2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  zip: z.string(),
  senderEmail: z.string().email().nullish(),
  featureFlags: featureFlagsSchema.nullish(),
  timezone: z.string().nullish(),
  fmhApiKey: z.string().nullish(),
  fmhSigningKey: z.string().nullish(),
  organizationType: z.string(),
  parentOrgId: z.string().nullish(),
  stockguardSlackChannel: z.string(),
});

export type AppSettings = z.infer<typeof appSettingsSchema>;

export const allAppSettingsResponseSchema = z.object({
  data: z.array(appSettingsSchema),
});

export type AllAppSettingsResponse = z.infer<
  typeof allAppSettingsResponseSchema
>;

export type DbAppSettingsInsert = Insertable<AppSettings>;
