import { z } from 'zod';
import {
  lgmCoverageTotalValuesSchema,
  lrpCoverageTotalValuesSchema,
} from './coverage-total-values';
import {
  dbLrpEndorsementSchema,
  dbLrpEndorsementInsertSchema,
} from './db-lrp-endorsement';
import {
  dbLgmEndorsementSchema,
  dbLgmEndorsementInsertSchema,
} from './db-lgm-endorsement';
import { zCoercedBoolean } from '@harvestiq/zod';

export const createLrpCoverageRequestSchema = dbLrpEndorsementInsertSchema
  .pick({
    type: true,
    commodityType: true,
    details: true,
    agentSignedAt: true,
    commodity: true,
    createdBy: true,
    customerSignedAt: true,
    externalStatus: true,
    isExternallyManaged: true,
    policyId: true,
    entityHistoryId: true,
    salesEffectiveDate: true,
    status: true,
    submittedToAipAt: true,
    watchedAt: true,
    liveCattleTargetCwtPerHead: true,
    feederCattleTargetCwtPerHead: true,
    cornTargetBuPerHead: true,
  })
  // API Endpoint specific fields
  .extend({
    coverageOwnerId: z.string().nullable(), // @note kept for backward compatibility @todo remove once FF is removed
    sendAgentNotification: z.boolean(),
    sendCustomerNotification: z.boolean(),
    enableEntities: zCoercedBoolean().optional(),
  })
  // Optional fields that are given default value by API if not provided
  .partial({
    createdBy: true,
  });
export type CreateLrpCoverageRequest = z.infer<
  typeof createLrpCoverageRequestSchema
>;

export const createLgmCoverageRequestSchema = dbLgmEndorsementInsertSchema
  .pick({
    type: true,
    commodityType: true,
    details: true,
    agentSignedAt: true,
    commodity: true,
    createdBy: true,
    customerSignedAt: true,
    externalStatus: true,
    isExternallyManaged: true,
    policyId: true,
    entityHistoryId: true,
    salesEffectiveDate: true,
    status: true,
    submittedToAipAt: true,
    watchedAt: true,
    liveCattleTargetCwtPerHead: true,
    feederCattleTargetCwtPerHead: true,
    cornTargetBuPerHead: true,
  })
  // API Endpoint specific fields
  .extend({
    coverageOwnerId: z.string().nullable(), // @note kept for backward compatibility @todo remove once FF is removed
    sendAgentNotification: z.boolean(),
    sendCustomerNotification: z.boolean(),
    enableEntities: zCoercedBoolean().optional(),
  })
  // Optional fields that are given default value by API if not provided
  .partial({
    createdBy: true,
  });
export type CreateLgmCoverageRequest = z.infer<
  typeof createLgmCoverageRequestSchema
>;

export const createCoverageRequestSchema = z.discriminatedUnion('type', [
  createLrpCoverageRequestSchema,
  createLgmCoverageRequestSchema,
]);
export type CreateCoverageRequest = z.infer<typeof createCoverageRequestSchema>;

export const createLrpCoverageResponseSchema = z.object({
  coverage: dbLrpEndorsementSchema.extend({
    // Virtual fields from Sequelize model
    // TODO: Remove this when we have a better way to handle virtual fields
    totalValues: lrpCoverageTotalValuesSchema,
  }),
  filename: z.string().nullable().optional(),
});

export type CreateLrpCoverageResponse = z.infer<
  typeof createLrpCoverageResponseSchema
>;

export const createLgmCoverageResponseSchema = z.object({
  coverage: dbLgmEndorsementSchema.extend({
    // Virtual fields from Sequelize model
    // TODO: Remove this when we have a better way to handle virtual fields
    totalValues: lgmCoverageTotalValuesSchema,
  }),
  filename: z.string().nullable().optional(),
});

export type CreateLgmCoverageResponse = z.infer<
  typeof createLgmCoverageResponseSchema
>;
