import { z } from 'zod';
import { LrpEndorsement, lrpEndorsementSchema } from './lrp-endorsement';
import { LgmEndorsement, lgmEndorsementSchema } from './lgm-endorsement';
import { InsureIQPolicyTypes } from '@harvestiq/constants';
import {
  DbLrpEndorsement,
  DbLrpEndorsementInsert,
  DbLrpEndorsementUpdate,
} from './db-lrp-endorsement';
import {
  DbLgmEndorsement,
  DbLgmEndorsementInsert,
  DbLgmEndorsementUpdate,
} from './db-lgm-endorsement';

export const coverageSchema = z.discriminatedUnion('type', [
  lrpEndorsementSchema,
  lgmEndorsementSchema,
]);

export type Coverage = z.infer<typeof coverageSchema>;

export const isLrpEndorsement = (
  coverage: Coverage
): coverage is LrpEndorsement => coverage.type === InsureIQPolicyTypes.LRP;

export const isLgmEndorsement = (
  coverage: Coverage
): coverage is LgmEndorsement => coverage.type === InsureIQPolicyTypes.LGM;

export function isDbLrpEndorsement(
  coverage: DbLrpEndorsement | DbLgmEndorsement
): coverage is DbLrpEndorsement {
  return coverage.type === InsureIQPolicyTypes.LRP;
}

export function isDbLrpEndorsementInsert(
  coverage: DbLrpEndorsementInsert | DbLgmEndorsementInsert
): coverage is DbLrpEndorsementInsert {
  return coverage.type === InsureIQPolicyTypes.LRP;
}

export function isDbLrpEndorsementUpdate(
  coverage: DbLrpEndorsementUpdate | DbLgmEndorsementUpdate
): coverage is DbLrpEndorsementUpdate {
  return coverage.type === InsureIQPolicyTypes.LRP;
}
export function isDbLgmEndorsement(
  coverage: DbLrpEndorsement | DbLgmEndorsement
): coverage is DbLgmEndorsement {
  return coverage.type === InsureIQPolicyTypes.LGM;
}

export function isDbLgmEndorsementUpdate(
  coverage: DbLrpEndorsementUpdate | DbLgmEndorsementUpdate
): coverage is DbLgmEndorsementUpdate {
  return coverage.type === InsureIQPolicyTypes.LGM;
}

export function isDbLgmEndorsementInsert(
  coverage: DbLrpEndorsementInsert | DbLgmEndorsementInsert
): coverage is DbLgmEndorsementInsert {
  return coverage.type === InsureIQPolicyTypes.LGM;
}

export function isDbLrpEndorsementInsertOrUpdate(
  coverage:
    | DbLrpEndorsementInsert
    | DbLrpEndorsementUpdate
    | DbLgmEndorsementInsert
    | DbLgmEndorsementUpdate
): coverage is DbLrpEndorsementInsert | DbLrpEndorsementUpdate {
  return coverage.type === InsureIQPolicyTypes.LRP;
}

export function isDbLgmEndorsementInsertOrUpdate(
  coverage:
    | DbLrpEndorsementInsert
    | DbLrpEndorsementUpdate
    | DbLgmEndorsementInsert
    | DbLgmEndorsementUpdate
): coverage is DbLgmEndorsementInsert | DbLgmEndorsementUpdate {
  return coverage.type === InsureIQPolicyTypes.LGM;
}
