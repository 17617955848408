import { useCallback, useMemo, useState, useEffect } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { paginationDefault, pageSizeOptions, noop, arraysEqual } from 'common'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { Toolbar } from './TableToolbar'
import { TableContext } from './context'
import { Body } from './TableBody'
import { Pagination } from './TablePagination'

export function Table({
  children,
  cellFormatters = {},
  columns,
  isCompact = false,
  isLoading,
  isSelectable = false,
  rows = [],
  id: tableId,
  title,
  searchColumns,
  bulkActions = [],
  hasTablePaginator = false,
  hasToolbar = false,
  hasTableHeader = true,
  className,
  isCollapsed = false,
  totalCount = rows?.length || 0,
  actions: actionsProp = {},
  onPaginationChange,
  onSortChange,
  sort,
  selectedRows: selectedRowsProp = [],
  onSelectRow = noop,
}) {
  const [selectedRows, setSelectedRowsState] = useState(
    new Set(selectedRowsProp),
  )
  const handleSetSelectedRows = useCallback(
    newSelectedRows => {
      setSelectedRowsState(newSelectedRows)
      onSelectRow(Array.from(newSelectedRows))
    },
    [onSelectRow],
  )
  const [expandedRows, setExpandedRows] = useState(new Set())
  const [filterState, setFilterState] = useQueryParams({
    pageSize: withDefault(NumberParam, paginationDefault.pageSize),
    page: withDefault(NumberParam, 0),
    filterValue: withDefault(StringParam, ''),
  })
  const { pageSize, page, filterValue } = filterState

  const updateFilterState = useCallback(
    (newFilterState = {}) => {
      setFilterState(newFilterState, 'pushIn')
    },
    [setFilterState],
  )

  useEffect(() => {
    const selectedRowsArray = Array.from(selectedRows)
    if (
      selectedRowsProp?.length &&
      !arraysEqual(selectedRowsProp, selectedRowsArray)
    ) {
      setSelectedRowsState(new Set(selectedRowsProp))
    }
  }, [selectedRowsProp, selectedRows])

  const value = useMemo(
    () => ({
      bulkActions,
      columns,
      expandedRows,
      filterValue,
      isCollapsed,
      isSelectable,
      page,
      pageSize,
      searchColumns,
      selectedRows,
      setExpandedRows,
      setSelectedRows: handleSetSelectedRows,
      tableId,
      title,
      totalCount,
      updateFilterState,
      onPaginationChange,
    }),
    [
      bulkActions,
      columns,
      expandedRows,
      filterValue,
      isCollapsed,
      isSelectable,
      page,
      pageSize,
      searchColumns,
      selectedRows,
      setExpandedRows,
      handleSetSelectedRows,
      tableId,
      title,
      totalCount,
      updateFilterState,
      onPaginationChange,
    ],
  )

  return (
    <TableContext.Provider value={value}>
      <Box sx={{ width: '100%' }} className={classNames('relative', className)}>
        <div>
          {children || (
            <>
              {hasToolbar && (
                <Toolbar
                  {...{
                    title,
                    selectedRows,
                    setSelectedRows: handleSetSelectedRows,
                    bulkActions,
                    rows,
                    searchColumns,
                    filterValue,
                    setFilterValue: val =>
                      updateFilterState({ filterValue: val }),
                  }}
                />
              )}

              <Body
                rows={rows}
                columns={columns}
                cellFormatters={cellFormatters}
                hasTableHeader={hasTableHeader}
                isSelectable={isSelectable}
                isLoading={isLoading}
                isCompact={isCompact}
                actions={actionsProp}
                sort={sort}
                onSortChange={onSortChange}
              />

              {hasTablePaginator && (
                <Pagination
                  rowsPerPageOptions={pageSizeOptions}
                  component="div"
                  count={totalCount}
                  rowsPerPage={pageSize}
                  page={page}
                />
              )}
            </>
          )}
        </div>
      </Box>
    </TableContext.Provider>
  )
}
