import { z } from 'zod';
import { dbEntityHistoryUpdateSchema } from './db-entity-history';
import { entityHistorySchema } from './entity-history';

export type UpdateEntitiesHistoryParams = {
  entitiesHistoryId: string;
  body: UpdateEntitiesHistoryRequest;
};

export const updateEntitiesHistoryRequestSchema = z.object({
  entity: dbEntityHistoryUpdateSchema,
});

export type UpdateEntitiesHistoryRequest = z.infer<
  typeof updateEntitiesHistoryRequestSchema
>;

export const updateEntitiesHistoryResponseSchema = entityHistorySchema;

export type UpdateEntitiesHistoryResponse = z.infer<
  typeof updateEntitiesHistoryResponseSchema
>;
