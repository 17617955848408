import { z } from 'zod';
import { dbPolicySchema } from './db-policy';
import { dbLocationSchema } from '../locations/db-locations';
import { InsureIQPolicyTypes } from '@harvestiq/constants';
import { DbOrderDirection } from '@harvestiq/constants';
import { dbEntityHistorySchema } from '../entities-history/db-entity-history';

export const getPoliciesRequestSchema = z.object({
  id: z.string().uuid().optional(),
  ids: z.array(z.string().uuid()).optional(),
  type: z.nativeEnum(InsureIQPolicyTypes).optional(),
  reinsuranceYears: z.array(z.coerce.number()).optional(),
  entityIds: z.array(z.string().uuid()).optional(),
  entitySearchText: z.string().optional(),
  entitySearchFields: z
    .array(z.enum(['firstName', 'lastName', 'businessName', 'email']))
    .optional(),
  skip: z.coerce.number().int().nonnegative().optional(),
  limit: z.coerce.number().int().positive().optional(),
  orderBy: z
    .string()
    .refine(
      (value: string) => Object.keys(dbPolicySchema.shape).includes(value),
      {
        message: 'Invalid orderBy field',
      }
    )
    .default('createdAt')
    .optional(),
  orderDirection: z
    .nativeEnum(DbOrderDirection)
    .default(DbOrderDirection.DESC)
    .optional(),
});

export type GetPoliciesRequest = z.infer<typeof getPoliciesRequestSchema>;

const legacyPolicyMailingAddress = dbLocationSchema
  .pick({
    city: true,
    county: true,
    state: true,
    zip: true,
    careOfName: true,
    country: true,
    addressType: true,
    coordinates: true,
  })
  .extend({
    addressLine1: z.string().nullable(),
    addressLine2: z.string().nullable(),
  });

export const legacyMergedPolicy = dbPolicySchema
  .merge(legacyPolicyMailingAddress.partial())
  .extend({
    livestockLocation: dbLocationSchema
      .pick({
        state: true,
        county: true,
        zip: true,
      })
      .optional(),
    application: z.string().nullable(),
    agentId: z.string().nullable(),
    entityHistory: dbEntityHistorySchema.nullable().optional(),
    entityId: z.string().nullable(),
  });

export type LegacyMergedPolicy = z.infer<typeof legacyMergedPolicy>;

export const getPoliciesResponseSchema = z.object({
  policies: z.array(legacyMergedPolicy),
  totalCount: z.number(),
});

export type GetPoliciesResponse = z.infer<typeof getPoliciesResponseSchema>;
