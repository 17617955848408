import { z } from 'zod';
import { dbEntityHistoryInsertSchema } from './db-entity-history';
import { entityHistorySchema } from './entity-history';

export const createEntitiesHistoryRequestSchema = z.object({
  entity: dbEntityHistoryInsertSchema,
  secondaryEntities: z.array(dbEntityHistoryInsertSchema).optional(),
});

export type CreateEntitiesHistoryRequest = z.infer<
  typeof createEntitiesHistoryRequestSchema
>;

export const createEntitiesHistoryResponseSchema = entityHistorySchema;

export type CreateEntitiesHistoryResponse = z.infer<
  typeof createEntitiesHistoryResponseSchema
>;
