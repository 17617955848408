import { DataProvider } from './DataProvider';

export enum FCM {
  adm = 'adm',
  marex = 'marex',
  rjobrien = 'rjobrien',
  stonex = 'stonex',
  CUNNINGHAM_QBO = 'cunninghamQBO',
  wedbush = 'wedbush',
}

export enum ContractImports {
  lighthouse = 'lighthouse',
}

export const S3_FOLDERS = {
  [FCM.adm]: 'admis',
  [FCM.marex]: 'marex',
  [FCM.rjobrien]: 'rjobrien',
  [FCM.stonex]: 'stonex',
  [FCM.CUNNINGHAM_QBO]: 'cunningham-qbo',
  [FCM.wedbush]: 'wedbush',
  [ContractImports.lighthouse]: 'lighthouse',
} as const;

export const IntegrationNames = {
  [DataProvider.ADM]: 'ADM',
  [DataProvider.Marex]: 'MAREX',
  [DataProvider.RJOBrien]: 'RJO',
  [DataProvider.StoneX]: 'STONEX',
  [DataProvider.Wedbush]: 'WEDBUSH',
};

export enum FileType {
  TRANSACTIONS = 1,
  POSITIONS = 2,
  BUST_MAKEUP_LEAVE = 3, // admis file for B, X, E transactions
  CONTRACTS = 4,
}
