import { DbTimestampGenerated, DbTimestampNullable } from '@harvestiq/utils';
import { zTimestamp } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';
import { GeneratedAlways, Insertable, Selectable, Updateable } from 'kysely';
import { z } from 'zod';

export interface DbUsersTable {
  id: GeneratedAlways<string>;
  firstName: string | null;
  lastName: string | null;
  email: string;
  password: string | null;
  middleName: string | null;
  suffix: string | null;
  timezone: string | null;
  confirmedAt: DbTimestampNullable;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
  deletedAt: DbTimestampNullable;
}

export type DbUser = Selectable<DbUsersTable>;
export type DbUserInsert = Insertable<DbUsersTable>;
export type DbUserUpdate = Updateable<DbUsersTable>;

export const dbUserSchema = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string(),
  password: z.string().nullable(),
  middleName: z.string().nullable(),
  suffix: z.string().nullable(),
  timezone: z.string().nullable(),
  confirmedAt: z.coerce.date().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});
expectTypeOf<keyof z.input<typeof dbUserSchema>>().toEqualTypeOf<
  keyof DbUser
>();
expectTypeOf<DbUser>().toEqualTypeOf<z.infer<typeof dbUserSchema>>();

// Remove sensitive fields from user
export const safeDbUserSchema = dbUserSchema.omit({ password: true });
export type SafeDbUser = Selectable<z.infer<typeof safeDbUserSchema>>;

export const dbUserInsertSchema = dbUserSchema
  .omit({
    id: true,
  })
  .partial()
  .extend({
    email: z.string(),
    confirmedAt: zTimestamp().nullable().optional(),
    createdAt: zTimestamp().optional(),
    updatedAt: zTimestamp().optional(),
    deletedAt: zTimestamp().nullable().optional(),
  });
type DbUserInsertFromSchema = z.input<typeof dbUserInsertSchema>;
expectTypeOf<keyof DbUserInsertFromSchema>().toEqualTypeOf<
  keyof DbUserInsert
>();
expectTypeOf<DbUserInsert>().toMatchTypeOf<DbUserInsertFromSchema>();

export const dbUserUpdateSchema = dbUserInsertSchema.partial();
type DbUserUpdateFromSchema = z.input<typeof dbUserUpdateSchema>;
expectTypeOf<keyof DbUserUpdateFromSchema>().toEqualTypeOf<
  keyof DbUserUpdate
>();
expectTypeOf<DbUserUpdate>().toMatchTypeOf<DbUserUpdateFromSchema>();
