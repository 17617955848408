import { z } from 'zod';

export const placePredictionSchema = z.object({
  description: z.string(),
  place_id: z.string(),
});

export type PlacePrediction = z.infer<typeof placePredictionSchema>;

export const getPlacesRequestSchema = z.object({
  input: z.string(),
});

export type GetPlacesRequest = z.infer<typeof getPlacesRequestSchema>;

export const getPlacesResponseSchema = z.object({
  places: z.array(placePredictionSchema),
});

export type GetPlacesResponse = z.infer<typeof getPlacesResponseSchema>;
