import { InsureIQLgmCommodityTypeNames } from '@harvestiq/constants';
import { zDecimalRequired, zIsoDate } from '@harvestiq/zod';
import { z } from 'zod';
import { addLgmTargetSchemaValidation } from './helpers';

const lgmVsCmeRequestSchemaObj = z.object({
  deductible: z.coerce.number(),
  finishingType: z.nativeEnum(InsureIQLgmCommodityTypeNames, {
    message: 'Invalid finishing type',
  }),
  salesEffectiveDate: zIsoDate(),
  liveCattleTargetCwtPerHead: zDecimalRequired().optional(),
  feederCattleTargetCwtPerHead: zDecimalRequired().optional(),
  cornTargetBuPerHead: zDecimalRequired().optional(),
  leanHogTargetCwtPerHead: zDecimalRequired().optional(),
  soybeanMealTargetTonPerHead: zDecimalRequired().optional(),
});

// /quoter/lgm-vs-cme
export const lgmVsCmeRequestSchema = addLgmTargetSchemaValidation({
  schema: lgmVsCmeRequestSchemaObj,
  lgmCommodityTypeNameField: 'finishingType',
});

// Using different schema than above to avoid Zod issue with returning
// wrong type from refine see: https://github.com/colinhacks/zod/issues/2474
export type LgmVsCmeRequest = z.infer<typeof lgmVsCmeRequestSchemaObj>;

// Generic schema for data series with dynamic keys (such as months)
const dynamicSeriesSchema = z.record(
  z.string(),
  z.union([z.number(), z.string(), z.null()])
);

const cmeSchema = z.object({
  corn: dynamicSeriesSchema,
  liveCattle: dynamicSeriesSchema.optional(),
  feederCattle: dynamicSeriesSchema.optional(),
  leanHog: dynamicSeriesSchema.optional(),
  soybeanMeal: dynamicSeriesSchema.optional(),
  cmeEquivalent: dynamicSeriesSchema,
});

const lgmSchema = z.object({
  producerPremium: dynamicSeriesSchema,
  basePremium: dynamicSeriesSchema,
  subsidy: dynamicSeriesSchema,
});

export const lgmVsCmeResponseSchema = z.object({
  data: z.array(
    z.object({
      cme: cmeSchema,
      lgm: lgmSchema,
    })
  ),
});

export type LgmVsCmeResponse = z.infer<typeof lgmVsCmeResponseSchema>;
