import { z } from 'zod';
import { safeUserSchema } from '../users/user';
import { dbEntitySchema } from './db-entity';

export const entitySchema = dbEntitySchema.extend({
  owner: safeUserSchema.nullable(),
});

export type Entity = z.infer<typeof entitySchema>;

export type SearchableEntityFields = keyof Pick<
  Entity,
  'firstName' | 'lastName' | 'businessName' | 'email'
>;
