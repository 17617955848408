/*
  See: https://pubfs-rma.fpac.usda.gov/pub/References/insurance_control_elements/PASS/2025/2025_D00100_IceAip_YTD.txt
*/

export enum RmaAipCodes {
  AA = 'AA',
  CM = 'CM',
  CP = 'CP',
  EF = 'EF',
  FA = 'FA',
  FH = 'FH',
  GA = 'GA',
  HU = 'HU',
  NA = 'NA',
  PL = 'PL',
  PS = 'PS',
  RH = 'RH',
  WN = 'WN',
}

export enum RmaAIPNames {
  AA = 'Test Insurance Provider',
  CM = 'Country Mutual Insurance Company',
  CP = 'Clear Blue Insurance Company',
  EF = 'Rural Community Insurance Company',
  FA = 'American Agricultural Insurance Company',
  FH = 'Farmers Mutual Hail Insurance Company of Iowa',
  GA = 'Great American Insurance Company',
  HU = 'Hudson Insurance Company',
  NA = 'NAU Country Insurance Company',
  PL = 'Producers Agriculture Insurance Company',
  PS = 'Palomar Specialty Insurance Company',
  RH = 'Ace American Insurance Company',
  WN = 'American Agri-Business Insurance Company',
}
