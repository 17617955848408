import { CalendarDate, zCalendarDate } from '@harvestiq/zod';
import { Dayjs, nowCdtDayjs } from './dayjs';

export const currentYear = new Date().getFullYear();

export const dateToCalendarDate = (date: Date): CalendarDate => {
  return zCalendarDate().parse(date.toISOString().slice(0, 10));
};

export const dayjsToCalendarDate = (date: Dayjs): CalendarDate => {
  return zCalendarDate().parse(date.format('YYYY-MM-DD'));
};

export const calendarDate = (dateString: string): CalendarDate => {
  return zCalendarDate().parse(dateString);
};

export const todayCalendarDate = (): CalendarDate => {
  return dayjsToCalendarDate(nowCdtDayjs());
};
