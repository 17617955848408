import { DateFormatShort } from '@harvestiq/constants';
import { LgmQuoteData } from './lgm-quote';
import { dayjs, isSwineCommodity } from '@harvestiq/utils';
import Decimal from 'decimal.js';
import { legacyLgmSeriesSchema } from './helpers';
import { zIsoDate } from '@harvestiq/zod';
import { z } from 'zod';

const legacyLgmQuotesResponseSchema = z.object({
  expectedPrices: legacyLgmSeriesSchema.array(),
  grossMargin: z.object({
    expectedGrossMargin: z
      .object({
        expectedGrossMargin: legacyLgmSeriesSchema,
      })
      .catchall(legacyLgmSeriesSchema),

    grossMarginGuarantee: legacyLgmSeriesSchema,
  }),
  salesEffectiveDate: zIsoDate(),
});

export type LegacyLgmQuotesResponse = z.infer<
  typeof legacyLgmQuotesResponseSchema
>;

/**
 * @deprecated For use in legacy LGM UI only
 */
export function createLegacySearchResponse(
  quoteData: LgmQuoteData
): LegacyLgmQuotesResponse {
  const formattedMarketingDates = quoteData.marketingDates.map(
    (marketingDate) => dayjs(marketingDate).format(DateFormatShort)
  );

  // Gross Margins
  const grossMarginGuarantee: LegacyLgmQuotesResponse['grossMargin']['grossMarginGuarantee'] =
    { series: 'Gross Margin Guarantee' };
  // Cast type as the LgmSeries schemas don't play nice here
  const expectedGrossMargin = {
    expectedGrossMargin: { series: 'Expected Gross Margin' },
  } as LegacyLgmQuotesResponse['grossMargin']['expectedGrossMargin'];
  // Expected Prices
  const expectedPrices: LegacyLgmQuotesResponse['expectedPrices'] = [];

  formattedMarketingDates.forEach((date, index) => {
    // Gross Margin Guarantee
    grossMarginGuarantee[date] = new Decimal(
      quoteData.grossMarginGuaranteesPerHead[index]
    ).toNumber();

    if (isSwineCommodity(quoteData.commodityTypeCode)) {
      // Expected Gross Margins
      const hasLeanHogPerHeadPrices = !!quoteData.leanHogPricesPerHead.length;
      const hasSoybeanMealPerHeadPrices =
        !!quoteData.soybeanMealPricesPerHead.length;
      const hasCornPerHeadPrices = !!quoteData.cornPricesPerHead.length;

      // Initialize the swine series
      if (hasLeanHogPerHeadPrices && !expectedGrossMargin['leanHog']) {
        expectedGrossMargin['leanHog'] = { series: 'Lean Hog' };
      }
      if (hasSoybeanMealPerHeadPrices && !expectedGrossMargin['soybeanMeal']) {
        expectedGrossMargin['soybeanMeal'] = { series: 'Soybean Meal' };
      }
      if (hasCornPerHeadPrices && !expectedGrossMargin['corn']) {
        expectedGrossMargin['corn'] = { series: 'Corn' };
      }

      if (hasLeanHogPerHeadPrices) {
        expectedGrossMargin['leanHog'][date] =
          quoteData.leanHogPricesPerHead[index] !== null
            ? new Decimal(quoteData.leanHogPricesPerHead[index]).toNumber()
            : null;
      }

      if (hasSoybeanMealPerHeadPrices) {
        expectedGrossMargin['soybeanMeal'][date] =
          quoteData.soybeanMealPricesPerHead[index] !== null
            ? new Decimal(quoteData.soybeanMealPricesPerHead[index]).toNumber()
            : null;
      }

      if (hasCornPerHeadPrices) {
        expectedGrossMargin['corn'][date] =
          quoteData.cornPricesPerHead[index] !== null
            ? new Decimal(quoteData.cornPricesPerHead[index]).toNumber()
            : null;
      }
      expectedGrossMargin.expectedGrossMargin[date] = new Decimal(
        quoteData.expectedGrossMarginsPerHead[index]
      ).toNumber();

      // Expected Prices
      const hasLeanHogPerCwtPrices = !!quoteData.leanHogPricesPerCwt.length;
      const hasSoybeanMealPerTonPrices =
        !!quoteData.soybeanMealPricesPerTon.length;
      const hasCornPerBuPrices = !!quoteData.cornPricesPerBu.length;

      // Initialize the swine series
      if (hasLeanHogPerCwtPrices && !expectedPrices[0]) {
        expectedPrices[0] = { series: 'Lean Hog' };
      }
      if (hasSoybeanMealPerTonPrices && !expectedPrices[1]) {
        expectedPrices[1] = { series: 'Soybean Meal' };
      }
      if (hasCornPerBuPrices && !expectedPrices[2]) {
        expectedPrices[2] = { series: 'Corn' };
      }
      const leanHogPrices = expectedPrices[0];
      const soybeanMealPrices = expectedPrices[1];
      const cornPrices = expectedPrices[2];

      if (hasLeanHogPerCwtPrices) {
        leanHogPrices[date] =
          quoteData.leanHogPricesPerCwt[index] !== null
            ? new Decimal(quoteData.leanHogPricesPerCwt[index]).toNumber()
            : null;
      }
      if (hasSoybeanMealPerTonPrices) {
        soybeanMealPrices[date] =
          quoteData.soybeanMealPricesPerTon[index] !== null
            ? new Decimal(quoteData.soybeanMealPricesPerTon[index]).toNumber()
            : null;
      }
      if (hasCornPerBuPrices) {
        cornPrices[date] =
          quoteData.cornPricesPerBu[index] !== null
            ? new Decimal(quoteData.cornPricesPerBu[index]).toNumber()
            : null;
      }
    }
    // Cattle
    else {
      // Expected Gross Margins
      // Initialize the cattle series
      if (!expectedGrossMargin['liveCattle']) {
        expectedGrossMargin['liveCattle'] = { series: 'Live Cattle' };
      }
      if (!expectedGrossMargin['feederCattle']) {
        expectedGrossMargin['feederCattle'] = { series: 'Feeder Cattle' };
      }
      if (!expectedGrossMargin['corn']) {
        expectedGrossMargin['corn'] = { series: 'Corn' };
      }

      const hasLiveCattlePerHeadPrices =
        !!quoteData.liveCattlePricesPerHead.length;
      const hasFeederCattlePerHeadPrices =
        !!quoteData.feederCattlePricesPerHead.length;
      const hasCornPerHeadPrices = !!quoteData.cornPricesPerHead.length;

      if (hasLiveCattlePerHeadPrices) {
        expectedGrossMargin['liveCattle'][date] =
          quoteData.liveCattlePricesPerHead[index] !== null
            ? new Decimal(quoteData.liveCattlePricesPerHead[index]).toNumber()
            : null;
      }

      if (hasFeederCattlePerHeadPrices) {
        expectedGrossMargin['feederCattle'][date] =
          quoteData.feederCattlePricesPerHead[index] !== null
            ? new Decimal(quoteData.feederCattlePricesPerHead[index]).toNumber()
            : null;
      }

      if (hasCornPerHeadPrices) {
        expectedGrossMargin['corn'][date] =
          quoteData.cornPricesPerHead[index] !== null
            ? new Decimal(quoteData.cornPricesPerHead[index]).toNumber()
            : null;
      }
      expectedGrossMargin.expectedGrossMargin[date] = new Decimal(
        quoteData.expectedGrossMarginsPerHead[index]
      ).toNumber();

      // Expected Prices
      // Initialize the cattle series
      if (!expectedPrices[0]) {
        expectedPrices[0] = { series: 'Live Cattle' };
      }
      if (!expectedPrices[1]) {
        expectedPrices[1] = { series: 'Feeder Cattle' };
      }
      if (!expectedPrices[2]) {
        expectedPrices[2] = { series: 'Corn' };
      }
      const liveCattlePrices = expectedPrices[0];
      const feederCattlePrices = expectedPrices[1];
      const cornPrices = expectedPrices[2];

      const hasLiveCattlePerCwtPrices =
        !!quoteData.liveCattlePricesPerCwt.length;
      const hasFeederCattlePerCwtPrices =
        !!quoteData.feederCattlePricesPerCwt.length;
      const hasCornPerBuPrices = !!quoteData.cornPricesPerBu.length;

      if (hasLiveCattlePerCwtPrices) {
        liveCattlePrices[date] =
          quoteData.liveCattlePricesPerCwt[index] !== null
            ? new Decimal(quoteData.liveCattlePricesPerCwt[index]).toNumber()
            : null;
      }
      if (hasFeederCattlePerCwtPrices) {
        feederCattlePrices[date] =
          quoteData.feederCattlePricesPerCwt[index] !== null
            ? new Decimal(quoteData.feederCattlePricesPerCwt[index]).toNumber()
            : null;
      }
      if (hasCornPerBuPrices) {
        cornPrices[date] =
          quoteData.cornPricesPerBu[index] !== null
            ? new Decimal(quoteData.cornPricesPerBu[index]).toNumber()
            : null;
      }
    }
  });

  return {
    grossMargin: {
      grossMarginGuarantee,
      expectedGrossMargin,
    },
    expectedPrices,
    salesEffectiveDate: quoteData.salesEffectiveDate,
  };
}
