import { z } from 'zod';
import { entityHistorySchema } from '../entities-history/entity-history';
import { dbPolicySchema } from './db-policy';

export const policySchema = dbPolicySchema.extend({
  // TODO: All policies should have an entity but can't be guaranteed as entityId is nullable in DB
  entityHistory: entityHistorySchema.nullable(),
  endorsementCount: z.number(),
  entityId: z.string().nullable(),
});

export type Policy = z.infer<typeof policySchema>;
