import { TablePagination as MuiTablePagination } from '@mui/material'
import { pageSizeOptions, paginationDefault } from 'common'
import { useCallback, useContext } from 'react'
import { TableContext } from './context'

export const Pagination = () => {
  const { totalCount, pageSize, page, updateFilterState, onPaginationChange } =
    useContext(TableContext)

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      updateFilterState({
        page: newPage,
      })
      onPaginationChange?.({ page: newPage, pageSize })
    },
    [updateFilterState, onPaginationChange, pageSize],
  )

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newPageSize = event?.target?.value
      const newPaginationState = {
        pageSize: newPageSize ?? paginationDefault.pageSize,
        page: paginationDefault.page,
      }
      updateFilterState(newPaginationState)
      onPaginationChange?.(newPaginationState)
    },
    [updateFilterState, onPaginationChange],
  )

  return (
    <MuiTablePagination
      rowsPerPageOptions={pageSizeOptions}
      component="div"
      count={totalCount}
      rowsPerPage={pageSize}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}
