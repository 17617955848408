import {
  LgmCattleTargetWeightsByCommodityTypeCode,
  InsureIQLgmCommodityTypeNames,
  InsureIQLrpCommodityTypeNames,
  RmaTypeCodes,
  LgmSwineTargetWeightsByCommodityTypeCode,
} from '@harvestiq/constants';
import { getReinsuranceYear, Dayjs } from '@harvestiq/utils';
import { z } from 'zod';

// Shape of { series: 'Some Series', 'Sep 2024': 2341.12, ...}
export const legacyLgmSeriesSchema = z.record(
  z.string(),
  z.string().or(z.number()).nullable()
);
export type LegacyLgmSeries = z.infer<typeof legacyLgmSeriesSchema>;

// This factor has potential to change from year to year and has no known name in the RMA documentation
// We are calling it the Simulated Losses Adjustment Factor (SLAF)
// See See Section 4, Total Premium Amount calc in https://www.rma.usda.gov/ftp/Publications/M13_Handbook/2024/approved/P16_1_Plan_82_Premium_Calculation.PDF
export const getLgmSimulatedLossesAdjustmentFactor = (
  salesEffectiveDate: string | Date | Dayjs
) => {
  const reinsuranceYear = getReinsuranceYear(salesEffectiveDate);

  if (reinsuranceYear >= 2025) {
    return 1.087;
  }
  if (reinsuranceYear >= 2024) {
    return 1.0638;
  }
  if (reinsuranceYear === 2022 || reinsuranceYear === 2023) {
    return 1.03;
  }
  return 1.03;
};

/**
 * Adds zod conditional validation of the LGM target fields of a schema based on the commodity type field.
 */
export const addLgmTargetSchemaValidation = <SchemaT extends z.ZodTypeAny>({
  schema,
  // TODO: Remove reliance on commodity type name and use only commodity type code
  lgmCommodityTypeNameField,
  lgmCommodityTypeCodeField,
}: {
  schema: SchemaT;
  lgmCommodityTypeNameField?: string;
  lgmCommodityTypeCodeField?: string;
}) => {
  if (!lgmCommodityTypeNameField && !lgmCommodityTypeCodeField) {
    throw new Error(
      'lgmCommodityTypeNameField or lgmCommodityTypeCodeField  is required'
    );
  }

  return schema.superRefine((data: any, ctx: z.RefinementCtx) => {
    const commodityTypeName = lgmCommodityTypeNameField
      ? (data[lgmCommodityTypeNameField] as InsureIQLgmCommodityTypeNames)
      : undefined;

    // FIXME: Use the correct RMA enum
    const commodityTypeCodeFromData = lgmCommodityTypeCodeField
      ? z
          .nativeEnum(RmaTypeCodes)
          .parse(data[lgmCommodityTypeCodeField].toString())
      : undefined;

    const liveCattleTargetCwtPerHead = data.liveCattleTargetCwtPerHead as
      | number
      | undefined;
    const feederCattleTargetCwtPerHead = data.feederCattleTargetCwtPerHead as
      | number
      | undefined;
    const cornTargetBuPerHead = data.cornTargetBuPerHead as number | undefined;

    const cattleCommodityTypeNameUsed =
      commodityTypeName &&
      (commodityTypeName === InsureIQLgmCommodityTypeNames.CALF_FINISHING ||
        commodityTypeName === InsureIQLgmCommodityTypeNames.YEARLING_FINISHING);
    const cattleCommodityTypeCodeUsed =
      commodityTypeCodeFromData &&
      (commodityTypeCodeFromData === RmaTypeCodes.CALF_FINISHING ||
        commodityTypeCodeFromData === RmaTypeCodes.YEARLING_FINISHING);

    if (cattleCommodityTypeNameUsed || cattleCommodityTypeCodeUsed) {
      let commodityTypeCode:
        | RmaTypeCodes.CALF_FINISHING
        | RmaTypeCodes.YEARLING_FINISHING
        | undefined;

      if (
        commodityTypeName === InsureIQLgmCommodityTypeNames.CALF_FINISHING ||
        commodityTypeCodeFromData === RmaTypeCodes.CALF_FINISHING
      ) {
        commodityTypeCode = RmaTypeCodes.CALF_FINISHING;
      } else {
        commodityTypeCode = RmaTypeCodes.YEARLING_FINISHING;
      }

      const liveCattleTargetWeightMin =
        LgmCattleTargetWeightsByCommodityTypeCode[commodityTypeCode].liveCattle
          .min;
      const liveCattleTargetWeightMax =
        LgmCattleTargetWeightsByCommodityTypeCode[commodityTypeCode].liveCattle
          .max;
      const feederCattleTargetWeightMin =
        LgmCattleTargetWeightsByCommodityTypeCode[commodityTypeCode]
          .feederCattle.min;
      const feederCattleTargetWeightMax =
        LgmCattleTargetWeightsByCommodityTypeCode[commodityTypeCode]
          .feederCattle.max;
      const cornTargetWeightMin =
        LgmCattleTargetWeightsByCommodityTypeCode[commodityTypeCode].corn.min;
      const cornTargetWeightMax =
        LgmCattleTargetWeightsByCommodityTypeCode[commodityTypeCode].corn.max;

      if (
        liveCattleTargetCwtPerHead !== undefined &&
        (liveCattleTargetCwtPerHead < liveCattleTargetWeightMin.toNumber() ||
          liveCattleTargetCwtPerHead > liveCattleTargetWeightMax.toNumber())
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `liveCattleTargetCwtPerHead for commodity ${
            commodityTypeName ?? commodityTypeCodeFromData
          } must be within ${liveCattleTargetWeightMin} and ${liveCattleTargetWeightMax}`,
        });
      }

      if (
        feederCattleTargetCwtPerHead !== undefined &&
        (feederCattleTargetCwtPerHead <
          feederCattleTargetWeightMin.toNumber() ||
          feederCattleTargetCwtPerHead > feederCattleTargetWeightMax.toNumber())
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `feederCattleTargetCwtPerHead for commodity ${
            commodityTypeName ?? commodityTypeCodeFromData
          } must be within ${feederCattleTargetWeightMin} and ${feederCattleTargetWeightMax}`,
        });
      }

      if (
        cornTargetBuPerHead !== undefined &&
        (cornTargetBuPerHead < cornTargetWeightMin.toNumber() ||
          cornTargetBuPerHead > cornTargetWeightMax.toNumber())
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `cornTargetBuPerHead for commodity ${
            commodityTypeName ?? commodityTypeCodeFromData
          } must be within ${cornTargetWeightMin} and ${cornTargetWeightMax}`,
        });
      }
    } else {
      if (liveCattleTargetCwtPerHead) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `liveCattleTargetCwtPerHead is not allowed for commodity ${
            commodityTypeName ?? commodityTypeCodeFromData
          }`,
          path: ['liveCattleTargetCwtPerHead'],
        });
      }
      if (feederCattleTargetCwtPerHead) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `feederCattleTargetCwtPerHead is not allowed for commodity ${
            commodityTypeName ?? commodityTypeCodeFromData
          }`,
          path: ['feederCattleTargetCwtPerHead'],
        });
      }
      if (cornTargetBuPerHead) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `cornTargetBuPerHead is not allowed for commodity ${
            commodityTypeName ?? commodityTypeCodeFromData
          }`,
          path: ['cornTargetBuPerHead'],
        });
      }
    }
  });
};

// See: LGM Cattle Handbook, Para 21, D(10) https://www.rma.usda.gov/-/media/RMA/Handbooks/Privately-Developed-Products---20000/Livestock-Gross-Margin-Cattle/2025-20060-LGM-Cattle-Insurance-Handbook.ashx
export const getLiveCattleTargetCwtPerHeadDefault = (
  commodityType:
    | InsureIQLgmCommodityTypeNames
    | InsureIQLrpCommodityTypeNames
    | RmaTypeCodes
) => {
  // Default values for Calf Finishing, 807
  if (
    commodityType === InsureIQLgmCommodityTypeNames.CALF_FINISHING ||
    commodityType === RmaTypeCodes.CALF_FINISHING
  ) {
    return LgmCattleTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.CALF_FINISHING
    ]?.liveCattle?.default;
  }
  // Default values for Yearling Finishing, 808
  if (
    commodityType === InsureIQLgmCommodityTypeNames.YEARLING_FINISHING ||
    commodityType === RmaTypeCodes.YEARLING_FINISHING
  ) {
    return LgmCattleTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.YEARLING_FINISHING
    ]?.liveCattle?.default;
  }
  return null;
};

// See: LGM Cattle Handbook, Para 21, D(10) https://www.rma.usda.gov/-/media/RMA/Handbooks/Privately-Developed-Products---20000/Livestock-Gross-Margin-Cattle/2025-20060-LGM-Cattle-Insurance-Handbook.ashx
export const getFeederCattleTargetCwtPerHeadDefault = (
  commodityType:
    | InsureIQLgmCommodityTypeNames
    | InsureIQLrpCommodityTypeNames
    | RmaTypeCodes
) => {
  // Default values for Calf Finishing, 807
  if (
    commodityType === InsureIQLgmCommodityTypeNames.CALF_FINISHING ||
    commodityType === RmaTypeCodes.CALF_FINISHING
  ) {
    return LgmCattleTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.CALF_FINISHING
    ].feederCattle.default;
  }
  // Default values for Yearling Finishing, 808
  if (
    commodityType === InsureIQLgmCommodityTypeNames.YEARLING_FINISHING ||
    commodityType === RmaTypeCodes.YEARLING_FINISHING
  ) {
    return LgmCattleTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.YEARLING_FINISHING
    ].feederCattle.default;
  }
  return null;
};

// See: LGM Cattle Handbook, Para 21, D(10) https://www.rma.usda.gov/-/media/RMA/Handbooks/Privately-Developed-Products---20000/Livestock-Gross-Margin-Cattle/2025-20060-LGM-Cattle-Insurance-Handbook.ashx
export const getCornTargetBuPerHeadDefault = (
  commodityType:
    | InsureIQLgmCommodityTypeNames
    | InsureIQLrpCommodityTypeNames
    | RmaTypeCodes
) => {
  // Default values for Calf Finishing, 807
  if (
    commodityType === InsureIQLgmCommodityTypeNames.CALF_FINISHING ||
    commodityType === RmaTypeCodes.CALF_FINISHING
  ) {
    return LgmCattleTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.CALF_FINISHING
    ].corn.default;
  }
  // Default values for Yearling Finishing, 808
  if (
    commodityType === InsureIQLgmCommodityTypeNames.YEARLING_FINISHING ||
    commodityType === RmaTypeCodes.YEARLING_FINISHING
  ) {
    return LgmCattleTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.YEARLING_FINISHING
    ].corn.default;
  }
  // Default values for Farrow to Finish, 804
  if (
    commodityType === InsureIQLgmCommodityTypeNames.FARROW_TO_FINISH ||
    commodityType === RmaTypeCodes.FARROW_TO_FINISH
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.FARROW_TO_FINISH
    ].corn.default;
  }
  // Default values for Finishing Operation, 805
  if (
    commodityType === InsureIQLgmCommodityTypeNames.FINISHING_OPERATION ||
    commodityType === RmaTypeCodes.FINISHING
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[RmaTypeCodes.FINISHING].corn
      .default;
  }
  // Default values for Sew Pig Finishing, 806
  if (
    commodityType === InsureIQLgmCommodityTypeNames.SEW_OPERATION ||
    commodityType === RmaTypeCodes.SEW_PIG_FINISHING
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.SEW_PIG_FINISHING
    ].corn.default;
  }
  return null;
};

export const getLeanHogTargetCwtPerHeadDefault = (
  commodityType:
    | InsureIQLgmCommodityTypeNames
    | InsureIQLrpCommodityTypeNames
    | RmaTypeCodes
) => {
  // Default values for Farrow to Finish, 804
  if (
    commodityType === InsureIQLgmCommodityTypeNames.FARROW_TO_FINISH ||
    commodityType === RmaTypeCodes.FARROW_TO_FINISH
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.FARROW_TO_FINISH
    ].leanHog.default;
  }
  // Default values for Finishing Operation, 805
  if (
    commodityType === InsureIQLgmCommodityTypeNames.FINISHING_OPERATION ||
    commodityType === RmaTypeCodes.FINISHING
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[RmaTypeCodes.FINISHING]
      .leanHog.default;
  }
  // Default values for Sew Pig Finishing, 806
  if (
    commodityType === InsureIQLgmCommodityTypeNames.SEW_OPERATION ||
    commodityType === RmaTypeCodes.SEW_PIG_FINISHING
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.SEW_PIG_FINISHING
    ].leanHog.default;
  }
  return null;
};

export const getSoybeanMealTargetTonPerHeadDefault = (
  commodityType:
    | InsureIQLgmCommodityTypeNames
    | InsureIQLrpCommodityTypeNames
    | RmaTypeCodes
) => {
  // Default values for Farrow to Finish, 804
  if (
    commodityType === InsureIQLgmCommodityTypeNames.FARROW_TO_FINISH ||
    commodityType === RmaTypeCodes.FARROW_TO_FINISH
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.FARROW_TO_FINISH
    ].soybeanMeal.default;
  }
  // Default values for Finishing Operation, 805
  if (
    commodityType === InsureIQLgmCommodityTypeNames.FINISHING_OPERATION ||
    commodityType === RmaTypeCodes.FINISHING
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[RmaTypeCodes.FINISHING]
      .soybeanMeal.default;
  }
  // Default values for Sew Pig Finishing, 806
  if (
    commodityType === InsureIQLgmCommodityTypeNames.SEW_OPERATION ||
    commodityType === RmaTypeCodes.SEW_PIG_FINISHING
  ) {
    return LgmSwineTargetWeightsByCommodityTypeCode[
      RmaTypeCodes.SEW_PIG_FINISHING
    ].soybeanMeal.default;
  }
  return null;
};
